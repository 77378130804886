import img1 from '../assets/img/bgin7.png';
import img2 from '../assets/img/bgweb.png';
import img3 from '../assets/img/bgweb1.png';
import img4 from '../assets/img/bgweb2.png'

const pameran = [
    {
    img:img1
    },
    {
    img:img2
    },
    {
    img:img3
    },
    {
    img:img2
    },
    {
    img:img4
    },
    {
    img:img1
    },
]

export default pameran;