import pameran from '../data/pameran';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';


function Pameran() {
    return (
        <div className="pameran">
        <h1>Random Photos</h1>
        <ResponsiveMasonry>
        <Masonry columns={4} spacing={2}>
            {pameran.map((pict,index)=>{
            return(
                <img src={pict.img} style={{margin:'1%'}} alt={`Random Photo ${index}`} />
                )
            })}
        </Masonry>
        </ResponsiveMasonry>
        </div>
    );
}

export default Pameran;
